import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import {socket} from '../../index.js';
import {code} from '../../index.js';
import {locale} from '../../index.js';
import {name} from '../../index.js';
import {pid} from '../../index.js';
import {renderElement} from '../../index.js';
import {isValidJSON} from '../../index.js';
import logoRu from './logo-ru.svg'
import logoEn from './logo-en.svg'
import joy from './itsajoytobeamongwehappyfew.svg'
import anger from './angrybidr.svg'
import sadness from './sadass.svg'
import qmark from './question.svg'

const localization = {
    "ru":{
        logo: logoRu,
        startGame: "Начать игру",
        chooseYourGroup: "Выберите группу:",
        quitGroup: "Выйти",
        rateByPrompt: "На сколько хорошо пара справилась со своей темой?",
        rateByScene: "Как вы оцениваете сценку в целом?",
        rateByEmotion: "Какой сцена была по настроению?",
        point: "балл",
        points: "балла",
        points1: "баллов",
        send: "Отправить",
        thanksForVote: "Спасибо за голос!",
        getReadyForPrompt: "Приготовьтесь импровизировать на тему:",
        theme: "Тема:",
        wellDone: ["Отлично сработано!", "Хорошая работа!", "Дай пять!", "Аплодисменты!"],
        thanksForPlaying: "Спасибо за игру!",
        retakeGame: "Повторим?",
        skipTutorial: "Пропустить обучение"
    },
    "en":{
        logo: logoEn,
        startGame: "Start game",
        quitGroup: "Quit",
        chooseYourGroup: "Choose Your Group:",
        rateByPrompt: "На сколько хорошо пара справилась со своей темой?",
        rateByScene: "Как вы оцениваете сценку в целом?",
        point: "point",
        points: "points",
        points1: "points",
        send: "Send",
        thanksForVote: "Thanks for vote!",
        getReadyForPrompt: "Get ready to improvise with:",
        theme: "Prompt:",
        wellDone: ["Well Done!", "Good Job!", "High Five!", "Applause!"],
        thanksForPlaying: "Thanks for playing!",
        retakeGame: "Restart?",
        skipTutorial: "Skip Tutorial"
    }
}

function req(todo, data){
    socket.emit('do', {do:todo, param:data});
}

function loc(key){
    const ball = localization[locale][key];
    if(Array.isArray(ball)){
        return ball[Math.floor(Math.random() * ball.length)]
    };
    return ball;
}

class gi{
    get(){
        if(localStorage.getItem("gameInfo")){
            return decodeURIComponent(escape(atob(localStorage.getItem("gameInfo"))))
        }
        return false
    }
    set(string){
        localStorage.setItem("gameInfo", btoa(unescape(encodeURIComponent(string))))
    }
}

const gameInfo = new gi



function getRuP(value, string0, string1, string2, string3){
    var number = value.toString();
    var lastDigit = number.charAt(number.length - 1);
    var last2Digit = number.charAt(number.length -2);
    if(value == 1){
        return loc(string0)
    }
    else if(last2Digit == 1 && lastDigit < 9 || lastDigit == 0){
        return loc(string1)
    }
    else if(lastDigit == 1){
        return loc(string2)
    }
    else if(lastDigit == 2 || lastDigit == 3 || lastDigit == 4){
        return loc(string3)
    }
    else if(lastDigit == 5 || lastDigit == 6 || lastDigit == 7 || lastDigit == 8 || lastDigit == 9){
        return loc(string1)
    }
}

//game-asses

function Imp2Setup(){
    const [jsx, setJSX] = useState((
        <div className="imp2">
                <div className="idle">
                    <img src={localization[locale].logo} className='logo'/>
                </div>
        </div>
    ))
    useEffect(() => {
        if(!localStorage.getItem('hash') || !isValidJSON(localStorage.getItem('hash'))){
            localStorage.setItem('hash', "{}");
        }
        var hashes = JSON.parse(localStorage.getItem('hash'))
    
        if(typeof hashes !== "object"){
        hashes = {};
        };

        
        socket.emit('requireReconnectTask', {code: code, hash: hashes[code]})

        socket.on('task', (data) => {
            console.log(data)
            const scenes = {
                "lobby": <Imp2Lobby sceneData={data.title.scenes} lastScene={data.params} isVip={data.title.isVip}/>,
                "wait": <Imp2JustWaitAbit/>,
                "tutorialSkip": <Imp2JustWaitAbit canSkip={true}/>,
                "thanks": <Imp2Thanks thanksText={data.title}/>,
                "themeWithAdditions": <Imp2PromptWithAdittion theme={data.title.theme} isPreState={data.title.isPreState} task={data.title.task} role={data.title.role}/>,
                "vote": <Imp2Vote/>,
                "post": <Imp2Post isVip={data.title.isVip}/>
            }
            setJSX(scenes[data.taskName])
            // switch(data.taskName){
            //     case("vote"):
            //         setJSX(<Imp2Vote />)
            //     break;
            //     case("lobby"):
            //         setJSX(Imp2Lobby(data.title))
            //     break;
            //     case("thanks"):
            //         setJSX(Imp2Thanks(data.title))
            //     break;
            //     case("themeWithAdditions"):
            //         setJSX(Imp2PromptWithAdittion(data.title.theme, data.title.isPreState, data.title.task))
            //     break;
            //     case("wait"):
            //         setJSX(<Imp2JustWaitAbit />)
            //     break;
            // }
        });

    }, []);

    return jsx
}


const moveGroup = (witchHEHEHEHA) => {
    const scenes =  document.getElementById("gameGroups").children;
    var counter = 0;
    if(witchHEHEHEHA != JSON.parse(gameInfo.get())["group"]){
        req("joinGroup", witchHEHEHEHA)
        gameInfo.set(JSON.stringify({group: witchHEHEHEHA}))
    }
    else{
        req("dejoinGroup")
        gameInfo.set(JSON.stringify({group: 12}))
    }

    while(counter != 4){
        var element = scenes[counter];
        if(counter != JSON.parse(gameInfo.get())["group"]){
            if(element.getAttribute("class")){
                element.removeAttribute("class");
            };
            element.innerHTML = `<div></div>`
        }
        else{
            element.innerHTML = `<div><p>${loc("quitGroup")}</p></div>`
            element.setAttribute("class", "chosenGroup")
        }
        counter++
    }
}

function Imp2Lobby(props){
    var data = props.sceneData
    // useEffect(() => {
    //     // const group = JSON.parse(gameInfo.get())["group"];
    //     // if(group){
    //     //     moveGroup(true, group);
    //     // }
    // }, [])
    var vip;
    if(props.isVip){
        vip = <button onClick={()=>req("startGame")}>{loc("startGame")}</button>
    }
    var scenes = [];
    for(let counter = 0; counter < 4; counter++){
        const group = <div key={counter} className={props.lastScene == counter ? "chosenGroup" : ""} id={data[counter]} onClick={() => moveGroup(counter)}><div>{props.lastScene == counter ? <p>{loc("quitGroup")}</p> : ""}</div></div>
        scenes.push(group)
    }
return (
    <div className={"imp2 " + locale}>
        <div className="lobby">
            <img src={localization[locale].logo} className='logo'/>
            <h1 className='playerName'>{name}</h1>
            <div id="Vip">
                {vip}
            </div>
            <h2>{loc("chooseYourGroup")}</h2>
            <div className='flex' id="gameGroups">
                {scenes}
            </div>
        </div>
    </div>
)}

function Imp2Thanks(props){
    return (
        <div className="imp2">
            <div className="idle">
                <img src={localization[locale].logo} className='logo'/>
                <h2>{loc(props.thanksText)}</h2>
            </div>
        </div>
    );
};


function Imp2JustWaitAbit(props){
    var canSkip;
    if(props.canSkip){
        canSkip = <button onClick={() => req("skipTutorial")}>{loc("skipTutorial")}</button>
    }
    return (
        <div className="imp2">
            <div className="waitabit">
                <img src={localization[locale].logo} className='logo'/>
                {canSkip}
            </div>
        </div>
    );
};


function Imp2PromptWithAdittion(props){

    var role = props.role ? <h1 className='role'>{props.role}</h1> : "";

    if(props.preState){
        var promptLine = <div><h1>{loc("getReadyForPrompt")}</h1> <br/> <h2>{props.theme}</h2> {role}</div>
    }
    else{
        var promptLine = <div><h2><b>{loc("theme")}</b> {props.theme}</h2> <br/> <h3>{props.task}</h3> {role}</div>
    };

    return (
        <div className="imp2">
            <div className="getReady">
                {promptLine}
            </div>
        </div>
    );
};

function Imp2Vote(){
    const [text1, setText] = useState('10');
    const [text2, setText2] = useState('10');
    const [pts1, setText3] = useState(loc("points1"));
    const [pts2, setText4] = useState(loc("points1"));

    const [sliderInput1Points, setpts1] = useState(10);
    const [sliderInput2Points, setpts2] = useState(10);
    const [emotionToSend, setemotion] = useState("none")

    const [imageRes, setImage] = useState(qmark);

    const sliderInput1 = (event) => {
        setpts1(event.target.value);
        setText(event.target.value);
        switch(locale){
            case("ru"):
            setText3(getRuP(event.target.value, "point", "points1", "point", "points"));
            break;
            case("en"):
            setText3(event.target.value == "1" ? loc("point") : loc("points"));
            break;
        }
    };
    const sliderInput2 = (event) => {
        setpts2(event.target.value);
        setText2(event.target.value);
        switch(locale){
            case("ru"):
            setText4(getRuP(event.target.value, "point", "points1", "point", "points"));
            break;
            case("en"):
            setText4(event.target.value == "1" ? loc("point") : loc("points"));
            break;
        }
    };

    function changeEmotion(a){
        setemotion(a)
        switch(a){
            case("joy"):
            setImage(joy)
            break;
            case("anger"):
            setImage(anger)
            break;
            case("sadness"):
            setImage(sadness)
            break;
        }
    }

    console.log(text1)

    return(
        <div className='imp2'>
            <div className='Vote'>
                {loc("rateByPrompt")}
                <div><input onInput={sliderInput1} type="range" min="0" max="10" step="1" /><div className='flex'><h3>{text1}</h3><h5>{pts1}</h5></div></div>
                {loc("rateByScene")}
                <div><input onInput={sliderInput2} type="range" min="0" max="10" step="1" /><div className='flex'><h3>{text2}</h3><h5>{pts2}</h5></div></div>
                {loc("rateByEmotion")}
                <div className='emotions'>
                    <img onClick={() => changeEmotion("joy")} src={joy}/>
                    <img onClick={() => changeEmotion("anger")} src={anger}/>
                    <img onClick={() => changeEmotion("sadness")} src={sadness}/>
                </div>
                <button onClick={() => req("rateTeam", [sliderInput1Points, sliderInput2Points, emotionToSend])}>
                    <div>
                        {text1} {pts1}, {text2} {pts2}, <img src={imageRes}/>
                    </div>
                    {loc("send")}
                </button>
            </div>
        </div>
    )
}

function Imp2Post(props){
    var vip;
    if(props.isVip){
        vip = <button onClick={()=>req("startGame")}>{loc("retakeGame")}</button>
    }
    return(
        <div className='imp2'>
            <div className='lobby'>
                <img src={localization[locale].logo} className='logo'/>
                <h2>{loc("thanksForPlaying")}</h2>
                {vip}
                <h1 className='playerName'>{name}</h1>
            </div>
        </div>
    )
}

export default Imp2Setup;
